import(/* webpackMode: "eager" */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["ImageBlock"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Block/ImageBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TextBlock"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Block/TextBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BrandHeader"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Brand/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollTopButton"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Form/Button/ScrollTopButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DefinedProductList"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/List/Product/DefinedProductList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/List/Product/FactFinderCampaignPageProductsList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FilteredProductList"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/List/Product/FilteredProductList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsletterForm"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Newsletter/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ActionBanner"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/pages/app/Banner/ActionBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TwoImageBanner"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/pages/app/Banner/TwoImageBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OneProductCampaign"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/pages/app/Campaign/OneProductCampaign.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomeLayout"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/pages/app/HomeLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BlockSlider"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/pages/app/Slider/BlockSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BrandSliderBlock"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/pages/app/Slider/BrandSliderBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TeaserMatrix"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/pages/app/TeaserMatrix.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TopImageCarousel"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/pages/app/TopImageCarousel/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PaymentCollapses"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/pages/information/Components/payments/PaymentCollapses.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Product/ProductCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TableOfContent"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Sidebar/TableOfContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImageSlider"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Slider/index.tsx");
